import React from 'react';
import { withNamespaces } from 'react-i18next';
import cl from 'classnames';
import s from './ApplicationsBody.module.scss';
import Link from '../Link';
import Api from '../../utils/api';
import NotAMemberComponent from '../NotAMemberComponent';

import winImg from '../../assets/img/applications/logo-windows.svg';
import macImg from '../../assets/img/applications/mac-os-logo.svg';
import iosImg from '../../assets/img/applications/logo-apple.svg';
import androidImg from '../../assets/img/applications/logo-android.svg';
import linuxImg from '../../assets/img/applications/logo-linux.svg';
import routerImg from '../../assets/img/applications/logo-router.svg';
import chromeImg from '../../assets/img/applications/logo-chrome.svg';

class ApplicationsBody extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
  }

  getTranslations() {
    const { t } = this.props;

    return {
      applicationList: [
        { platform: t('page_applications:app_win'), imgSrc: winImg, link: this.api.applicationWindowsUrl() },
        { platform: t('page_applications:app_mac'), imgSrc: macImg, link: this.api.applicationMacOsUrl() },
        { platform: t('page_applications:app_linux'), imgSrc: linuxImg, link: this.api.applicationLinuxUrl() },
        { platform: t('page_applications:app_ios'), imgSrc: iosImg, link: this.api.applicationIosUrl() },
        { platform: t('page_applications:app_android'), imgSrc: androidImg, link: this.api.applicationAndroidUrl() },
        { platform: t('page_applications:app_router'), imgSrc: routerImg, link: this.api.applicationRouterUrl() },
        { platform: t('page_applications:app_chrome'), imgSrc: chromeImg, link: this.api.applicationExtensionUrl() },
      ],
      reviews: [
        { text: t('page_applications:rev_1'), author: t('page_application:anonymous') },
        { text: t('page_applications:rev_2'), author: t('page_application:anonymous') },
      ],
    };
  }

  render() {
    const { t, brand, children } = this.props;
    const { applicationList } = this.getTranslations();

    const childrens = React.Children.map(children, c => c);

    const Application = ({ className, platform, imgSrc, link }) => (
      <Link className={className} to={link}>
        <div className={cl(s.application__imgContainer)}>
          <img alt={platform} className={cl(s.application__img)} src={imgSrc} />
        </div>
        <div className={cl(s.application__platform)}>
          {platform}
        </div>
      </Link>
    );

    return (
      <div className={s.page}>
        <div className={cl(s.wrapper, s.wrapper_fixedHeight, s.wrapper_color_gray, s.wrapper__applicationShiftBottom)}>
          <div className={cl(s.innerWrapper, s.header)}>
            {childrens[0]}
          </div>
        </div>
        <div className={cl(s.wrapper, s.page__block_144)}>
          <div className={cl(s.innerWrapper)}>
            <div className={cl(s.applicationList)}>
              <div className={cl(s.applicationList__wrapper)}>
                {applicationList.map(({ platform, imgSrc, link }) => (
                  <Application
                    key={`application${platform}`}
                    imgSrc={imgSrc}
                    platform={platform}
                    className={s.application}
                    link={link}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={cl(s.wrapper, s.wrapper__applicationShiftTop)}>
          <div className={cl(s.innerWrapper, s.cardBlock)}>
            <h2 className={cl(s.h2, s.cardBlock__title)}>
              {t('page_applications:h2')}
            </h2>
            <div
              className={cl(s.cardBlock__subtitle)}
               dangerouslySetInnerHTML={{ __html: t('page_applications:subtitle') }}
            />
            <div className={cl(s.cardBlock__cards)}>
              {childrens[1]}
            </div>
          </div>
        </div>
        <div>
          <div className={cl(s.innerWrapper)}>
            {childrens[2]}
          </div>
        </div>
        <NotAMemberComponent
          brand={brand.name}
          text={t('page_applications:not_a_member')}
          buttonText={t('page_applications:get_anonine')}
          orderUrl={this.api.orderUrl()}
        />
      </div>
    );
  }
}

export default withNamespaces()(ApplicationsBody);
