import React from 'react';
import { withNamespaces } from 'react-i18next';

import Application from './Application';
import ListOfFeatures from './ListOfFeatures';
import Review from './Review';
import PageHeaderTitle from '../PageHeaderTitle';

import img from '../../assets/img/anonine-landing/anonine_platforms_reverse.svg';

import card1Img from '../../assets/img/applications/nologs.svg';
import card3Img from '../../assets/img/applications/noun-refund-1355939.svg';
import card4Img from '../../assets/img/applications/noun-swedish-912926.svg';
import card5Img from '../../assets/img/applications/global.svg';
import card6Img from '../../assets/img/applications/group-10.svg';
import card9Img from '../../assets/img/applications/connect.svg';

export const AllApplications = withNamespaces()(({ t, brand }) => (
  <Application
    h1={t('page_applications:h1')}
    text={t('page_applications:text_1')}
    img={{ img, img2: img, img3: img }}
    maxHeight='400px'
  >
    <PageHeaderTitle brandName={brand.name} title={t('page_applications:applications')} />
  </Application>
));

export const ListOfFeaturesAll = withNamespaces()(({ t }) => (
  <ListOfFeatures cards={[
    { title: t('page_applications:card_1_title'), text: t('page_applications:card_1_text'), imgSrc: card1Img },
    { title: t('page_applications:card_4_title'), text: t('page_applications:card_4_text'), imgSrc: card4Img },
    { title: t('page_applications:card_9_title'), text: t('page_applications:card_9_text'), imgSrc: card9Img },
    { title: t('page_applications:card_5_title'), text: t('page_applications:card_5_text'), imgSrc: card5Img },
    { title: t('page_applications:card_3_title'), text: t('page_applications:card_3_text'), imgSrc: card3Img },
    { title: t('page_applications:card_6_title'), text: t('page_applications:card_6_text'), imgSrc: card6Img },
  ]}
  />
));

export const ReviewAll = withNamespaces()(({ t }) => (
  <Review
    text={t('page_applications:review_3')}
    author={t('page_applications:anonymous')}
  />
));
