import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';

import Layout from '../layouts/index';
import PageHeader from '../components/PageHeader';
import PageFooter from '../components/PageFooter';
import PageContent from '../styled/PageContent';
import ApplicationsBody from '../components/AppicationsPage/ApplicationsBody';
import { AllApplications, ListOfFeaturesAll, ReviewAll } from '../components/AppicationsPage/AllApplications';

class ApplicationsPage extends React.Component {
  render() {
    const { gatsbyUrl, pythonUrl, brand, affiliateUrl } = get(this.props, 'data.site.siteMetadata');
    const { location } = this.props;

    return (
      <Layout location={location}>
        <div>
          <PageHeader
            brand={brand}
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
          />
          <PageContent>
            <ApplicationsBody
              gatsbyUrl={gatsbyUrl}
              pythonUrl={pythonUrl}
              brand={brand}
            >
              <AllApplications
                gatsbyUrl={gatsbyUrl}
                pythonUrl={pythonUrl}
                brand={brand}
              />
              <ListOfFeaturesAll />
              <ReviewAll />
            </ApplicationsBody>
          </PageContent>
          <PageFooter
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
            brand={brand}
            location={location}
            affiliateUrl={affiliateUrl}
          />
        </div>
      </Layout>
    );
  }
}

const pageQuery = graphql`
  query ApplicationsPageQuery {
    site {
      siteMetadata {
        brand {
          name
          nameCom
          twitter
          facebook
        }
        gatsbyUrl
        pythonUrl
        affiliateUrl
      }
    }
  }
`;

export default ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={data => <ApplicationsPage data={data} location={location} />}
  />
);
