import React from 'react';
import cl from 'classnames';

import { withNamespaces } from 'react-i18next';
import s from './Application.module.scss';

const Application = ({ children, text, h1, imgSrc, t, list, img, imgAlt, maxHeight }) => (
  <div className={s.component}>
    <div className={cl(s.img)}>
      <div className={cl(s.imgContainer)}>
        <picture>
          <img
            style={{ maxHeight }}
            srcSet={`${img.img} 600w, ${img.img2} 900w, ${img.img3} 1440w`}
            src={img.img3}
            type='image/jpeg'
            alt={imgAlt}
          />
        </picture>
      </div>
    </div>
    <div className={s.textBlock}>
      <h1 className={cl(s.h1)} dangerouslySetInnerHTML={{ __html: h1 }} />
      <div className={cl(s.text, s.component__text)}>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      <ul className={cl(s.list)} dangerouslySetInnerHTML={{ __html: list }} />
      {children}
    </div>
  </div>
);

export default withNamespaces()(Application);
